import React, { useId, useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";

import { ReactComponent as ChevronDownIcon } from "../assets/chevron_down.svg";
import { ReactComponent as ChevronUpIcon } from "../assets/chevron_up.svg";
import { ReactComponent as ErrorIcon } from "../assets/errorInfoIcon.svg";

const errorVariants = {
  open: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.2,
    },
  },
  closed: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.2,
    },
  },
};
const errorContainerVariants = {
  open: {
    outline: "2px solid #E30C0C",
  },
  close: {
    outline: "0px",
  },
};

const isTouchScreenDevice = () => {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
};

const ControlledInput = (props: any) => {
  const { value, onChange, ...rest } = props;
  const [cursor, setCursor] = useState<number>();
  const ref = useRef<
    HTMLInputElement & HTMLTextAreaElement & HTMLSelectElement
  >(null);

  useEffect(() => {
    if (isTouchScreenDevice()) return;
    const input = ref.current;
    if (input) {
      input.selectionStart = Number(cursor);
      input.selectionEnd = Number(cursor);
    }
  }, [ref, cursor, value]);

  const handleChange = (e: any) => {
    setCursor(e.target.selectionStart);
    onChange && onChange(e);
  };

  return <input ref={ref} value={value} onChange={handleChange} {...rest} />;
};

type UpDownControlProps = {
  icon?: any;
  text?: JSX.Element;
  value?: number;
  initialValue?: number;
  minValue?: number;
  maxValue?: number;
  decimal?: number;
  resolution?: number;
  readOnly?: boolean;
  onChange?: (newValue: number) => void;
  showError?: number;
  errorText?: JSX.Element[] | JSX.Element;
};

export default function UpDownControl(props: UpDownControlProps) {
  const id = useId();

  //resolution of the control. default is 0.1
  const resolution = props.resolution ?? 0.1;
  //external value that tracks value prop.
  // const [value, setValue] = useState<number>(props.initialValue ?? props.value ?? 0);
  //internal value that changes with control elements.
  const [_value, _setValue] = useState<number>(
    props.initialValue ?? props.value ?? 0
  );

  //when value prop changes, update internal value which is displayed on the element.
  useEffect(() => {
    if (!(props.value === undefined)) _setValue(props.value);
  }, [props.value]);

  //when internal value changes, update external value by calling onChange callback.
  const handleChange = (newValue: number) => {
    //prevent updating external value if readOnly is true.
    if (props.readOnly) return;

    //limit min and max values.
    if (props.minValue !== undefined && newValue <= props.minValue) {
      newValue = props.minValue;
    }
    if (props.maxValue !== undefined && newValue >= props.maxValue) {
      newValue = props.maxValue;
    }

    _setValue(newValue);
    props.onChange?.(newValue);
  };

  return (
    <div className="relative">
      <motion.div
        variants={errorContainerVariants}
        initial={"close"}
        animate={props.showError ? "open" : "close"}
        id={`up-down-control-${id}`}
        className={`w-full h-full bg-accent flex lg:grid lg:grid-rows-2 lg:grid-cols-5 flex-row justify-between items-center ${
          props.readOnly ? "text-[#4d4d4d]" : "text-white"
        } py-2 px-5 md:px-6 rounded-lg lg:rounded-3xl`}
      >
        <div className="flex lg:grid lg:grid-rows-1 lg:grid-cols-4 flex-row items-center lg:col-span-4 lg:col-start-1">
          {props.icon}
          <p className="ml-2 lg:ml-0 text-sm md:font-semibold text-tertiary lg:col-span-3">
            {props.text}
          </p>
        </div>

        <ControlledInput
          id={`up-down-control-input-${id}-${
            props.readOnly ? "readonly" : ""
          }}`}
          type="text"
          value={props.decimal ? _value.toFixed(props.decimal) : _value}
          onChange={(e: any) => {
            //if the value is not a number, return.
            if (isNaN(Number(e.target.value))) return;
            handleChange(Number(e.target.value));
          }}
          className={`focus:outline-none text-end lg:text-start bg-transparent lg:mr-0 max-w-fit w-1/3 md:w-auto ml-auto lg:ml-0 font-semibold text-base md:text-base lg:col-start-2 lg:col-span-3 ${
            props.showError ? "text-error" : ""
          }`}
          readOnly={props.readOnly}
          inputMode="numeric"
        />

        <div
          className={`flex flex-col lg:col-start-5 ml-1 md:ml-5 lg:ml-10 lg:row-start-1 lg:row-span-2 lg:items-end ${
            props.readOnly ? "opacity-0" : ""
          }`}
        >
          <button>
            <ChevronUpIcon
              className="m-0 p-0 w-3 h-3 md:w-4 md:h-4 text-white"
              onClick={() => {
                handleChange(_value + resolution);
              }}
            ></ChevronUpIcon>
          </button>
          <button>
            <ChevronDownIcon
              className="m-0 p-0 w-3 h-3 md:w-4 md:h-4 text-white"
              onClick={() => {
                handleChange(_value - resolution);
              }}
            ></ChevronDownIcon>
          </button>
        </div>
      </motion.div>
      <motion.div
        variants={errorVariants}
        initial={"closed"}
        animate={props.showError ? "open" : "closed"}
        className="bg-errorSoft text-error font-bold rounded-[10px] text-[11px] overflow-hidden absolute -bottom-[8px] w-full translate-y-full z-30"
      >
        <div className="px-4 py-2">
          <ErrorIcon className="w-5 mr-2 inline" />
          {Array.isArray(props.errorText) && props.showError
            ? props.errorText[props.showError - 1]
            : props.errorText}
        </div>
      </motion.div>
    </div>
  );
}
