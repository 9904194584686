import React, { useEffect, useState, useRef } from "react";
import "./App.css";

import { ReactComponent as CrownIcon } from "./assets/crown.svg";
import { ReactComponent as CogIcon } from "./assets/cog.svg";
import { ReactComponent as DollarIcon } from "./assets/dollarIcon.svg";
import { ReactComponent as AutoPlayIcon } from "./assets/autoplayIcon.svg";
import { ReactComponent as BetAmountIcon } from "./assets/betAmountIcon.svg";
import { ReactComponent as WinChanceIcon } from "./assets/winChanceIcon.svg";
import { ReactComponent as TrophyIcon } from "./assets/trophyIcon.svg";
import casino_bg from "./assets/bg-bet.png";

import { motion } from "framer-motion";

import UpDownControl from "./components/UpDownControl";
import CheckboxControl from "./components/CheckboxControl";
import BetLogs from "./components/BetLogCard";
import BetDetailsModal from "./components/BetDetailsModal";
import {
  BetLogDataProps,
  BetLogModalContextProvider,
} from "./contexts/BetLogModalContext";
// import ErrorModal from "./components/ErrorModal";

import { FormattedMessage, IntlProvider } from "react-intl";
import { messages } from "./assets/messages";

import Slots from "./components/Slots";

import GameManager from "./api/APIUtils";

const debug = false;

//moved url params out of the component
const urlParams = new URLSearchParams(window.location.search);
const apiKey = urlParams.get("apiKey") ?? "";
const token = urlParams.get("token") ?? "";
const lang = String(urlParams.get("lang") ?? "en");

const gameManager = new GameManager(
  "https://mv-api.oddpan.com",
  apiKey,
  token,
  "fd04ce99500489e79d79"
);

const updateBetHistory = (betHistory: any, newBet: any) => {
  const newBetHistory = [...betHistory];
  const betHistoryCount = newBetHistory.unshift(newBet);
  if (betHistoryCount > 100) {
    console.log("betHistoryCount exceeded 100, popping last element");
    newBetHistory.pop();
  }
  return newBetHistory;
};

function App() {
  //refs
  const slotsRef = useRef<any>(null);

  //bet state management
  const [gameModeSelection, setGameModeSelection] = useState(0);
  const [isOver, setIsOver] = useState(false);
  const [betAmount, setBetAmount] = useState(0);
  const [winChance, setWinChance] = useState(50);
  const [profitOnWin, setProfitOnWin] = useState(0);
  const [autoPlayAmount, setAutoPlayAmount] = useState(0);
  const [rollingAutoPlayAmount, setRollingAutoPlayAmount] = useState(0);
  const [underValue, setUnderValue] = useState(5000);
  const [overValue, setOverValue] = useState(4999);
  const [readyToPlay, setReadyToPlay] = useState(false);
  const [rolling, setRolling] = useState(false);
  const [balance, setBalance] = useState(0);
  const [nonce, setNonce] = useState(0);
  const [showBetAmountError, setShowBetAmountError] = useState(0);
  const [showWinChanceError, setShowWinChanceError] = useState(0);
  const [currency, setCurrency] = useState("$");

  const [lastPlayedGame, setLastPlayedGame] = useState({
    date: "08/21/2023",
    betID: "123456789",
    betAmount: 0.00000001,
    digits: 2,
    game: "Under500",
    chance: 50,
    roll: 50,
    profit: 100000,
    serverSeed: "123456789",
    hashedServerSeed: "123456789",
    clientSeed: "123456789",
    nonce: 123456789,
  });
  const [betHistory, setBetHistory] = useState<BetLogDataProps[]>([]);

  // const [showErrorModal, setShowErrorModal] = useState(false);
  // const [errorTitle, setErrorTitle] = useState<JSX.Element>();
  // const [errorMessage, setErrorMessage] = useState<JSX.Element>();
  // const [errorTimeout, setErrorTimeout] = useState<any>();
  useEffect(() => {
    const underVal = Number(((winChance / 100) * 10000).toFixed(0));
    const overVal = Number((10000 - (winChance / 100) * 10000 - 1).toFixed(0));

    setUnderValue(underVal);
    setOverValue(overVal);
  }, [winChance]);

  useEffect(() => {
    (async () => {
      const result = await gameManager.CalculateProfitOnWin(
        winChance,
        betAmount
      );
      setProfitOnWin(result);
    })();
  }, [winChance, betAmount]);

  gameManager.subscribeToReadyToPlay(setReadyToPlay);
  gameManager.subscribeToRolling(setRolling);
  gameManager.subscribeToAutoPlayAmount(setRollingAutoPlayAmount);
  gameManager.subscribeToBalance(setBalance);
  gameManager.subscribeToNonce(setNonce);
  gameManager.subscribeToLastPlayedGame(setLastPlayedGame);
  gameManager.subscribeToCurrency(setCurrency);

  useEffect(() => {
    if (lastPlayedGame.betAmount === 0) return;
    setBetHistory((b: any) => updateBetHistory(b, lastPlayedGame));
  }, [lastPlayedGame]);

  /*
   * variants for framer-motion to animate gameModeSelection outline
   */
  const variant = {
    //manual
    0: {
      x: 0,
      transition: {
        ease: "easeInOut",
      },
    },
    //auto
    1: {
      x: "100%",
      transition: {
        ease: "easeInOut",
      },
    },
    //flash
    2: {
      x: "200%",
      transition: {
        ease: "easeInOut",
      },
    },
  };

  // const showError = (title: JSX.Element, message: JSX.Element) => {
  //   clearTimeout(errorTimeout);
  //   setErrorTitle(title);
  //   setErrorMessage(message);
  //   setShowErrorModal(true);
  //   setErrorTimeout(
  //     setTimeout(() => {
  //       setShowErrorModal(false);
  //     }, 2000)
  //   );
  // };

  const mainDivRef = useRef<HTMLDivElement>(null);

  if (
    // /(iPhone|iPod|iPad)/i.test(navigator.userAgent) ||
    // /Mobi|Android/i.test(navigator.userAgent)
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(
      navigator.userAgent
    )
  ) {
    window.addEventListener(
      "focus",
      (event) => {
        if (event.target instanceof HTMLInputElement) {
          if (!mainDivRef.current) return;
          if (event.target.id.includes("readonly")) return;
          // window.scrollTo({
          //   top: (document.activeElement as HTMLDivElement)?.offsetTop - 100,
          // });
          // console.log(1, event.target, event.relatedTarget);
          mainDivRef.current.style.height = "50lvh";
          mainDivRef.current.scrollTo(0, 0);
          window.scrollTo(0, 0);
          document.activeElement?.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }
      },
      true
    );

    window.addEventListener(
      "blur",
      (event) => {
        if (event.target instanceof HTMLInputElement) {
          if (event.relatedTarget instanceof HTMLInputElement) return;
          if (!mainDivRef.current) return;
          console.log(2, event.target, event.relatedTarget);
          mainDivRef.current.style.height = "100lvh";
          window.scrollTo(0, 0);
          mainDivRef.current.scrollTo(0, 0);
          if (!document.activeElement) return;
          (document.activeElement as HTMLElement).blur();
        }
        // if (event.target instanceof Window) {
        //   if (!mainDivRef.current) return;
        //   mainDivRef.current.style.height = "100lvh";
        //   window.scrollTo(0, 0);
        //   mainDivRef.current.scrollTo(0, 0);
        // }
      },
      true
    );
  }
  return (
    <IntlProvider locale={lang} defaultLocale="en" messages={messages[lang]}>
      <div className="h-full w-full overflow-y-scroll flex">
        <div
          id="main-div"
          ref={mainDivRef}
          className="max-w-screen max-h-screen h-full w-full bg-primary pt-5 px-4 md:px-8 lg:px-52 pb-10 flex flex-col font-inter overflow-y-scroll"
        >
          <div
            id="header"
            className="items-center w-full justify-between hidden"
          >
            <div
              id="logo-container"
              className="hidden lg:block mr-auto text-white text-7xl font-extrabold text-justify"
            >
              L O <br /> GO
            </div>
            <div
              id="profile-picture-container"
              className=" w-fit h-fit relative ml-1"
            >
              <img
                id="profile-picture"
                src={require("./assets/profile_picture_male.jpg")}
                alt=""
                className="w-10 h-10 md:w-12 md:h-12 lg:w-10 lg:h-10 rounded-full"
              />
              <div
                id="vip-status-outline"
                className="bg-[#E08857] w-fit h-fit absolute right-0 bottom-0 p-[2px] md:p-[3px] lg:p-[2px] rounded-full translate-x-2"
              >
                <CrownIcon
                  id="vip-status-icon"
                  className="text-white w-3 h-3 md:w-4 md:h-4 lg:w-3 lg:h-3"
                />
              </div>
            </div>
            <div
              id="greeting"
              className="text-white flex-1 text-3xl m-5 md:text-[40px] lg:text-2xl md:tracking-wide lg:max-w-fit"
            >
              <FormattedMessage id={"greeting"} />, Mert
            </div>
            <CogIcon className="text-white w-6 md:w-12 lg:hidden" />
          </div>

          <div id="game-header-container" className="flex gap-5 h-fit">
            <div
              id="game-type-selection"
              className="w-full lg:w-2/3 bg-accent rounded-2xl p-2 px-8 grid-cols-3 relative hidden"
            >
              <div
                id="game-type-selection-manual"
                className={`text-center px-6 py-3 ${
                  gameModeSelection === 0
                    ? "text-white font-semibold"
                    : "text-[#4D4D4D]"
                } text-sm md:text-xl lg:text-base z-10`}
                onClick={() => {
                  setGameModeSelection(0);
                }}
              >
                <FormattedMessage id="manual" />
              </div>
              <div
                id="game-type-selection-auto"
                className={`text-center px-6 py-3 ${
                  gameModeSelection === 1
                    ? "text-white font-semibold"
                    : "text-[#4D4D4D]"
                } text-sm md:text-xl lg:text-base z-10`}
                onClick={() => {
                  setGameModeSelection(1);
                }}
              >
                <FormattedMessage id="auto" />
              </div>
              <div
                id="game-type-selection-flash"
                className={`text-center px-6 py-3 ${
                  gameModeSelection === 2
                    ? "text-white font-semibold"
                    : "text-[#4D4D4D]"
                } text-sm md:text-xl lg:text-base z-10`}
                onClick={() => {
                  setGameModeSelection(2);
                }}
              >
                <FormattedMessage id="flash" />
              </div>
              <div
                id="selection-outline-container"
                className="absolute w-full h-full p-[inherit]"
              >
                <motion.div
                  id="selection-outline"
                  variants={variant}
                  animate={String(gameModeSelection)}
                  className="h-full rounded-lg w-1/3 md:px-4"
                >
                  <div
                    id="selection-box"
                    className="bg-primary h-full w-full rounded-lg"
                  ></div>
                </motion.div>
              </div>
            </div>
            <div
              id="balance-container"
              className="aspect-[2.9] md:aspect-[2.45] w-[24vw] md:w-[15vw] lg:w-[10vw] min-w-[10vw] gap-[12px] flex flex-row justify-center items-center bg-accent rounded-full ml-auto font-semibold md:font-bold text-sm md:text-xl text-white"
            >
              <TrophyIcon className="w-[16px] md:w-[20px] lg:w-[25px] text-secondary" />
              <p>
                {currency}
                {balance}
              </p>
            </div>
          </div>

          <div id="game-container" className="md:mt-8">
            <div
              id="round-container"
              className="text-xs md:text-lg text-tertiary"
            >
              Round <br className="lg:hidden" />
              <p className="font-semibold text-sm md:text-[20px] md:font-bold lg:inline">
                {nonce}
              </p>
            </div>
            <div
              id="game-window-container"
              style={{
                background: `linear-gradient(0deg, rgba(28, 25, 44, 0.90)0%, rgba(28, 25, 44, 0.90)100%), url(${casino_bg}) lightgray 50% / cover no-repeat`,
              }}
              className="flex items-center justify-center mt-5 bg-cover bg-no-repeat bg-center w-full rounded-xl bg-opacity-80 "
            >
              <div
                id="game-window"
                className="w-full h-full text-white grid grid-cols-4 gap-3 relative px-[14%] lg:px-[34%] py-[83px] md:py-[72px]"
              >
                <Slots ref={slotsRef} />
                <div
                  id="autoplay-counter-container"
                  className={`absolute w-full right-0 bottom-0 flex-row gap-2 ${
                    gameModeSelection === 1 || autoPlayAmount >= 2
                      ? "flex"
                      : "hidden"
                  } items-center justify-center md:justify-end`}
                >
                  <div className=" w-fit bg-accent rounded-full border flex flex-row px-4 py-2 m-2">
                    <AutoPlayIcon className="w-6 aspect-square md:w-6 md:h-6 text-secondary" />
                    <p>{rollingAutoPlayAmount}</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="game-controls-container"
              className="mt-6 md:mt-8 gap-3 md:gap-6 flex flex-col"
            >
              <div
                id="game-controls-top"
                className="gap-4 flex flex-col lg:flex-row"
              >
                <UpDownControl
                  icon={
                    <BetAmountIcon className="w-[25px] lg:w-[2vw] text-secondary" />
                  }
                  text={<FormattedMessage id="betAmount" />}
                  decimal={2}
                  value={betAmount}
                  minValue={0}
                  onChange={setBetAmount}
                  showError={showBetAmountError}
                  errorText={[
                    <FormattedMessage key={1} id="betAmountErrorDescription" />,
                    <FormattedMessage
                      key={2}
                      id="insufficientBalanceErrorDescription"
                    />,
                  ]}
                />
                <UpDownControl
                  icon={
                    <WinChanceIcon className="w-[25px] lg:w-[2vw] text-secondary" />
                  }
                  text={<FormattedMessage id="winChance" />}
                  decimal={2}
                  resolution={1}
                  value={winChance}
                  minValue={0}
                  maxValue={98}
                  onChange={setWinChance}
                  showError={showWinChanceError}
                  errorText={
                    <FormattedMessage id="winChanceErrorDescription" />
                  }
                />
                <UpDownControl
                  icon={
                    <DollarIcon className="w-[25px] lg:w-[2vw] text-secondary" />
                  }
                  text={<FormattedMessage id="profitOnWin" />}
                  decimal={2}
                  value={profitOnWin}
                  onChange={setProfitOnWin}
                  readOnly
                />
                <UpDownControl
                  icon={
                    <AutoPlayIcon className="w-[25px] lg:w-[2vw] text-secondary" />
                  }
                  text={<FormattedMessage id="autoplay" />}
                  decimal={0}
                  resolution={1}
                  value={autoPlayAmount}
                  minValue={0}
                  maxValue={100}
                  onChange={setAutoPlayAmount}
                />
              </div>
              <div
                id="game-controls-bottom"
                className="flex flex-col lg:flex-row gap-3 md:gap-6 lg:gap-12"
              >
                <div
                  id="checkbox-controls-container"
                  className="flex flex-row justify-between w-full lg:w-2/3 gap-3 lg:gap-6"
                >
                  <CheckboxControl
                    onChange={() => {
                      setIsOver(false);
                    }}
                    value={!isOver}
                    text={
                      <FormattedMessage
                        id={"under"}
                        values={{ n: underValue }}
                      />
                    }
                  />
                  <CheckboxControl
                    onChange={() => {
                      setIsOver(true);
                    }}
                    value={isOver}
                    text={
                      <FormattedMessage id={"over"} values={{ n: overValue }} />
                    }
                  />
                </div>

                <div
                  id="play-button"
                  className="mt-5 lg:mt-0 w-full bg-secondary rounded-xl justify-center flex items-center py-4 text-white font-semibold md:font-bold text-sm md:text-xl cursor-pointer select-none"
                  onClick={() => {
                    console.log("readyToPlay", readyToPlay);
                    console.log("rolling", rolling);
                    if (rolling) {
                      gameManager.InterruptPlaying();
                      return;
                    }
                    if (!readyToPlay) {
                      gameManager.readyToPlay = true;
                      return;
                    }
                    if (winChance <= 0 || winChance >= 98) {
                      // showError(
                      //   <FormattedMessage id="winChanceErrorTitle" />,
                      //   <FormattedMessage id="winChanceErrorDescription" />
                      // );
                      setShowWinChanceError(1);
                      setTimeout(() => {
                        setShowWinChanceError(0);
                      }, 2000);
                      return;
                    }
                    if (betAmount <= 0) {
                      // showError(
                      //   <FormattedMessage id="betAmountErrorTitle" />,
                      //   <FormattedMessage id="betAmountErrorDescription" />
                      // );
                      setShowBetAmountError(1);
                      setTimeout(() => {
                        setShowBetAmountError(0);
                      }, 2000);
                      return;
                    }
                    if (betAmount > balance) {
                      // showError(
                      //   <FormattedMessage id="insufficientBalanceErrorTitle" />,
                      //   <FormattedMessage id="insufficientBalanceErrorDescription" />
                      // );
                      setShowBetAmountError(2);
                      setTimeout(() => {
                        setShowBetAmountError(0);
                      }, 2000);
                      return;
                    }
                    gameManager.Play(
                      betAmount,
                      underValue,
                      winChance,
                      isOver,
                      slotsRef,
                      autoPlayAmount,
                      debug
                    );
                  }}
                >
                  <p>
                    {readyToPlay ? (
                      <FormattedMessage id="play" />
                    ) : rolling ? (
                      <FormattedMessage id="stop" />
                    ) : (
                      <FormattedMessage id="registerToPlay" />
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <BetLogModalContextProvider betHistory={betHistory}>
            <div id="my-bets-header" className="text-white mt-6">
              <h1 className="mb-8 text-lg md:text-2xl">
                <FormattedMessage id={"betHistory"} />
              </h1>
            </div>
            <BetLogs />
            <BetDetailsModal />
          </BetLogModalContextProvider>
          {/* <ErrorModal
          showModal={showErrorModal}
          errorTitle={errorTitle}
          errorMessage={errorMessage}
        /> */}
        </div>
      </div>
    </IntlProvider>
  );
}

export default App;
