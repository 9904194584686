import React, { useEffect, useState, useId } from "react";
import { ReactComponent as TickIcon } from "../assets/tick.svg";

export default function CheckboxControl(props: any) {
  const id = useId();

  const [value, setValue] = useState<boolean>(false);
  const [_value, _setValue] = useState<boolean>(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (newValue: boolean) => {
    _setValue(newValue);
    props.onChange(newValue);
  };

  return (
    <div
      id={`checkbox-control-${id}`}
      className={`w-full bg-accent px-2 md:px-4 rounded-lg lg:rounded-3xl flex justify-center md:justify-start items-center lg:gap-2 text-sm font-normal md:font-semibold transition-colors duration-500 text-tertiary`}
      onClick={() => {
        handleChange(!_value);
      }}
    >
      <div
        className={`border rounded aspect-square w-6 m-2 transition-colors duration-500 ${
          value ? "border-none bg-secondary" : "border-[#4D4D4D]"
        }`}
      >
          <TickIcon
            className={`w-full h-full text-white transition-opacity duration-700 ${
              value ? "opacity-100" : "opacity-0"
            }`}
          />
      </div>
      {props.text}
    </div>
  );
}
