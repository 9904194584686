import axios, { AxiosInstance } from "axios";
import Pusher, { Channel } from "pusher-js";

export default class GameManager {
  private endpoint: string;
  private instance: AxiosInstance;
  private pusher: Pusher;
  private channel: Channel | null = null;
  private balanceAbortController: AbortController = new AbortController();
  private profitAbortController: AbortController = new AbortController();

  private apiKey: string = "";
  private token: string = "";

  private userId: string = "";
  private tokenId: string = "";
  private socketId: string = "";

  get autoPlayAmount() {
    return this._autoPlayAmount;
  }
  private set autoPlayAmount(value: number) {
    this._autoPlayAmount = value;
    this._autoPlayAmountSubscribers.forEach((callback) => {
      callback(value);
    });
  }
  private _autoPlayAmount: number = 0;
  private _autoPlayAmountSubscribers: Function[] = [];

  public subscribeToAutoPlayAmount(callback: Function) {
    if (this._autoPlayAmountSubscribers.includes(callback)) return;
    this._autoPlayAmountSubscribers.push(callback);
  }

  private slotsRef: any;

  get balance() {
    return this._balance;
  }
  private set balance(value: number) {
    this._balanceSubscribers.forEach((callback) => {
      callback(value);
    });
    this._balance = value;
  }
  public UpdateAndGetBalance() {
    this.UpdateBalance();
    return this.balance;
  }
  private _balance: number = 0;
  private _balanceSubscribers: Function[] = [];
  public subscribeToBalance(callback: Function) {
    if (this._balanceSubscribers.includes(callback)) return;
    this._balanceSubscribers.push(callback);
    callback(this._balance);
  }

  get currency() {
    return this._currency;
  }
  private set currency(value: string) {
    this._currencySubscribers.forEach((callback) => {
      callback(value);
    });
    this._currency = value;
  }
  private _currency: string = "";
  private _currencySubscribers: Function[] = [];
  public subscribeToCurrency(callback: Function) {
    if (this._currencySubscribers.includes(callback)) return;
    this._currencySubscribers.push(callback);
    callback(this._currency);
  }

  get nonce() {
    return this._nonce;
  }
  private set nonce(value: number) {
    this._nonceSubscribers.forEach((callback) => {
      callback(value);
    });
    this._nonce = value;
  }
  private _nonce: number = 0;
  private _nonceSubscribers: Function[] = [];
  public subscribeToNonce(callback: Function) {
    if (this._nonceSubscribers.includes(callback)) return;
    this._nonceSubscribers.push(callback);
    callback(this._nonce);
  }

  //setter will be private in production
  get readyToPlay() {
    return this._readyToPlay;
  }
  set readyToPlay(value: boolean) {
    this._readyToPlay = value;
    this._readyToPlaySubscribers.forEach((callback) => {
      callback(value);
    });
  }
  private _readyToPlay: boolean = false;
  private _readyToPlaySubscribers: Function[] = [];

  public subscribeToReadyToPlay(callback: Function) {
    if (this._readyToPlaySubscribers.includes(callback)) return;
    this._readyToPlaySubscribers.push(callback);
    callback(this._readyToPlay);
  }

  get rolling() {
    return this._rolling;
  }

  set rolling(value: boolean) {
    this._rolling = value;
    this._rollingSubscribers.forEach((callback) => {
      callback(value);
    });
  }
  private _rolling: boolean = false;
  private _rollingSubscribers: Function[] = [];

  public subscribeToRolling(callback: Function) {
    if (this._rollingSubscribers.includes(callback)) return;
    this._rollingSubscribers.push(callback);
    callback(this._rolling);
  }

  private autoPlayBetAmount = 0;
  private autoPlayPrediction = 0;
  private autoPlayWinChance = 0;
  private autoPlayIsOver = true;
  private autoPlayDebug = false;
  private autoPlayInterrupt = false;
  private autoPlayTimeout: ReturnType<typeof setTimeout> = setTimeout(
    () => "",
    1000
  );

  get lastPlayedGame() {
    return this._lastPlayedGame;
  }
  private set lastPlayedGame(value) {
    this._lastPlayedGame = value;
  }
  private _lastPlayedGame = {
    date: "",
    betID: "",
    betAmount: 0,
    digits: 2,
    game: "",
    chance: 0,
    roll: 0,
    profit: 0,
    serverSeed: "",
    hashedServerSeed: "",
    clientSeed: "",
    nonce: 0,
  };
  private _lastPlayedGameSubscribers: Function[] = [];
  public subscribeToLastPlayedGame(callback: Function) {
    if (this._lastPlayedGameSubscribers.includes(callback)) return;
    this._lastPlayedGameSubscribers.push(callback);
    callback(this._lastPlayedGame);
  }
  private publishLastPlayedGame() {
    this._lastPlayedGameSubscribers.forEach((callback) => {
      callback(this._lastPlayedGame);
    });
  }

  // to workaround channel from triggering event before subscription
  // private axiosHeaders = { "Content-Type": "application/json" };
  // private axiosTimeout = 1000;
  // private pusherAppKey = '';
  // private pusherCluster = "eu";
  // private roundIdReceived = false;

  constructor(
    endpoint: string = "https://mv-api.oddpan.com",
    apiKey: string = "mvgaming",
    token: string = "e48a906a2e3746bb8813834361acf4da",
    appKey: string = "fd04ce99500489e79d79",
    cluster: string = "eu",
    headers = { "Content-Type": "application/json" },
    timeout = 10000
  ) {
    this.endpoint = endpoint;

    //create axios instance
    this.instance = this.SetAxiosInstance(headers, timeout);

    //create pusher instance
    this.pusher = this.SetPusherInstance(appKey, cluster);

    //set user variables
    this.apiKey = apiKey;
    this.token = token;

    //make request to /user to get user info
    this.SetUser(apiKey, token);
  }

  private SetAxiosInstance = (
    headers = { "Content-Type": "application/json" },
    timeout = 10000
  ) => {
    return axios.create({
      baseURL: `${this.endpoint}/api`,
      headers: headers,
      timeout: timeout,
    });
  };

  private SetPusherInstance = (appKey: string, cluster: string) => {
    return new Pusher(appKey, {
      cluster: cluster,
      authEndpoint: `${this.endpoint}/pusher/auth`,
    });
  };

  public async SetUser(apiKey: string, token: string) {
    await this.instance
      .get("/user", {
        params: {
          apiKey: apiKey,
          token: token,
        },
      })
      .then((res) => {
        if (res.status === 200 && res.data.code === 0) {
          console.log("request to user: ", res.data);
          this.currency = res.data.currency;
          this.tokenId = res.data.tokenId;
          this.balance = res.data.balance;
          this.userId = "placeholder";
          this.apiKey = apiKey;
          this.token = token;
          return { success: true, status: res.status };
        }
        return { success: false, status: res.status };
      })
      .catch((err) => {
        console.log(err);
        return { success: false, status: err.status };
      });

    this.channel?.unbind_all();
    this.channel = this.pusher.subscribe(
      "private-dice-tokenId=" + this.tokenId
    );

    this.channel.bind("pusher:subscription_succeeded", async () => {
      console.log("subscription succeeded");
      this.socketId = this.pusher.connection.socket_id;
      this.nonce = await this.getRoundId();
      this.readyToPlay = true;
    });

    this.channel.bind("pusher:subscription_error", (e: any) => {
      console.log("subscription error");
      console.log(e)
    });

    this.channel.bind("ReadyToRoll", this.handleReadyToRoll);
    this.channel.bind("CreditRequestSent", this.handleCreditRequestSent);
  }

  private handleReadyToRoll = (data: any) => {
    console.log("ready to roll: ", data);
    this.readyToPlay = true;
    this.nonce = data.Nonce;
    this.socketId = data.SocketId;
  };

  private getRoundId = async () => {
    return (
      await this.instance.get("/round", {
        params: {
          socket_id: this.socketId,
          channel_name: "private-dice-tokenId=" + this.tokenId,
        },
      })
    ).data.nonce;
  };

  private handleCreditRequestSent = (data: any) => {
    if (data.SocketId === this.pusher?.connection.socket_id) {
      console.log(data);
      console.log("CreditRequestSent");
      console.log("Nonce: ", data.Nonce);
      console.log("Result: ", data.Result);
      // here will play again if autoplay amount is above 0
      // because this event is triggered by the play request
      this.slotsRef.current.stopRolling(data.Result);
      this.setLastPlayedGame({
        profit: (data.Balance - this.balance),
        roll: data.Result,
        serverSeed: data.ServerSeed,
      });
      this.publishLastPlayedGame();
      this.balance = data.Balance;
      this.nonce = data.Nonce;
      this.autoPlayAmount--;
      if (this.autoPlayAmount > 0) {
        this.autoPlayTimeout = setTimeout(() => {
          this.PlayAgain(
            this.autoPlayBetAmount,
            this.autoPlayPrediction,
            this.autoPlayWinChance,
            this.autoPlayIsOver,
            this.autoPlayDebug
          );
        }, 1000);
      } else {
        this.readyToPlay = true;
        this.rolling = false;
      }
    }
  };

  private setLastPlayedGame = (data: any) => {
    this.lastPlayedGame = { ...this.lastPlayedGame, ...data };
  };

  private mockCreditRequestSent(
    betAmount: number = 0,
    prediction: number = 5000,
    winChance: number = 0,
    isOver: boolean = true,
    debug = false
  ) {
    this.slotsRef.current.stopRolling(Math.random() * 9999);
    this.autoPlayAmount--;
    if (this.autoPlayAmount > 0) {
      setTimeout(() => {
        this.PlayAgain(betAmount, prediction, winChance, isOver, debug);
      }, 1000);
    } else {
      this.readyToPlay = true;
      this.rolling = false;
    }
  }

  private async UpdateBalance() {
    console.log("called updateBalance");
    this.balanceAbortController.abort();
    this.balanceAbortController = new AbortController();
    this.instance
      .get("/user", {
        signal: this.balanceAbortController.signal,
        params: {
          apiKey: this.apiKey,
          token: this.token,
        },
      })
      .then((res) => {
        if (res.status === 200 && res.data.code === 0) {
          console.log("request to user: ", res.data);
          this.balance = res.data.credit;
        }
      })
      .catch((err) => {
        if (err.name === "CanceledError") return;
        console.log("error in updateBalance");
        console.log(err);
      });
  }

  public async CalculateProfitOnWin(winChance: number, betAmount: number) {
    this.profitAbortController?.abort();
    this.profitAbortController = new AbortController();
    if (winChance === 0) {
      return 0;
    }
    return await this.instance
      .get("/prediction", {
        signal: this.profitAbortController.signal,
        params: {
          amount: betAmount,
          probablity: winChance,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return res.data.credit;
        }
      })
      .catch((err) => {
        console.log("error in getProfitOnWin");
        console.log(err);
        return 0;
      });
  }

  private async PlaceBet(
    betAmount: number = 0,
    prediction: number = 5000,
    winChance: number = 0,
    isOver: boolean = true,
    debug = false
  ) {
    console.log(
      "called placeBet with: ",
      betAmount,
      prediction,
      isOver,
      debug,
      this.socketId,
      this.apiKey,
      this.token
    );
    if (debug) {
      setTimeout(() => {
        this.mockCreditRequestSent(betAmount, prediction, winChance, isOver, debug);
      }, 1000);
      return;
    }

    this.setLastPlayedGame({
      date: new Date().toLocaleDateString('tr-TR').replace(/\./g, "/"),
      time: new Date().toLocaleTimeString('tr-TR'),
      betID: this.nonce,
      betAmount: betAmount,
      digits: 2,
      game: "Under5000",
      chance: winChance,
      serverSeed: "placeholder",
      hashedServerSeed: "placeholder",
      clientSeed: "placeholder",
      nonce: this.nonce,
    });

    const options = {
      socketId: this.socketId,
      prediction: prediction,
      // userKey ??
      userKey: "",
      apiKey: this.apiKey,
      token: this.token,
      amount: betAmount,
      isOver: isOver,
    };

    console.log("options: ", options);

    this.instance
      .post("/debit", options)
      .then((res) => {
        if (res.status === 200) {
          console.log("request to debit: ", res.data);
          if (res.data.code === 101) {
            this.InterruptPlaying();
          }
        }
      })
      .catch((err) => {
        console.log("error in debit");
        console.log(err);
      });
  }

  public Play(
    betAmount: number,
    prediction: number,
    winChance: number,
    isOver: boolean,
    slotsRef: any,
    autoPlayAmount: number,
    debug = false
  ) {
    console.log("called play", this.readyToPlay)
    if (!this.readyToPlay) return;

    this.slotsRef = slotsRef;
    this.autoPlayInterrupt = false;

    slotsRef.current.startRolling();
    this.readyToPlay = false;
    this.rolling = true;
    this.autoPlayAmount = autoPlayAmount === 0 ? 1 : autoPlayAmount;
    this.autoPlayBetAmount = betAmount;
    this.autoPlayPrediction = prediction;
    this.autoPlayWinChance = winChance;
    this.autoPlayIsOver = isOver;
    this.autoPlayDebug = debug;
    this.PlaceBet(betAmount, prediction, winChance, isOver, debug);
  }

  private PlayAgain = (
    betAmount: number,
    prediction: number,
    winChance: number,
    isOver: boolean,
    debug: boolean
  ) => {
    if (this.autoPlayInterrupt) return;
    this.slotsRef.current.startRolling();
    this.PlaceBet(betAmount, prediction, winChance, isOver, debug);
  };

  public InterruptPlaying() {
    console.log("interrupting play");
    clearTimeout(this.autoPlayTimeout);
    this.autoPlayInterrupt = true;
    this.readyToPlay = true;
    this.rolling = false;
  }
}
