export const messages : {[key : string] : {}} = {
  en: {
    greeting: 'Hello',
    manual: 'Manual',
    auto: 'Auto',
    flash: 'Flash',
    round: 'Round',
    betAmount: 'Bet Amount',
    winChance: 'Win Chance',
    autoplay: 'Autoplay',
    profitOnWin: 'Profit On Win',
    under: 'Under {n, number, ::group-off}',
    over: 'Over {n, number, ::group-off}',
    registerToPlay: 'Register To Play',
    play: 'Play',
    rolling: 'Rolling',
    stop: 'Stop',
    betHistory: 'Bet History',
    date: 'Date',
    betId: 'Bet ID',
    roll: 'Roll',
    profit: 'Profit',
    betAmountErrorTitle: 'Bet Amount Error',
    betAmountErrorDescription: 'Bet amount can not be 0.',
    insufficientBalanceErrorTitle: "Insufficient Balance",
    insufficientBalanceErrorDescription: "You don't have enough balance.",
    winChanceErrorTitle: "Win Chance Error",
    winChanceErrorDescription: "Win chance must be between 0 and 98.",
  },
  tr: {
    greeting: 'Merhaba',
    manual: 'Manuel',
    auto: 'Otomatik',
    flash: 'Flash',
    round: 'Round',
    betAmount: 'Bahis Miktarı',
    winChance: 'Kazanma Şansı',
    autoplay: 'Otomatik Oynat',
    profitOnWin: 'Kazanç',
    under: '{n, number, ::group-off}\'den Az',
    over: '{n, number, ::group-off}\'den Fazla',
    registerToPlay: 'Kayıt Ol',
    play: 'Oyna',
    rolling: 'Oynanıyor',
    stop: 'Durdur',
    betHistory: 'Bahis Geçmişi',
    date: 'Tarih',
    betId: 'Bahis ID',
    roll: 'Roll',
    profit: 'Kazanç',
    betAmountErrorTitle: 'Bahis Miktarı Hatası',
    betAmountErrorDescription: 'Bahis miktarı 0 olamaz.',
    insufficientBalanceErrorTitle: "Yetersiz Bakiye",
    insufficientBalanceErrorDescription: "Yeterli bakiyeniz yok.",
    winChanceErrorTitle: "Kazanma Şansı Hatası",
    winChanceErrorDescription: "Kazanma şansı 0 ile 98 arasında olmalıdır.",
  },
}