import React, { useRef } from "react";

import { ReactComponent as CloseIcon } from "../assets/close.svg";
import { ReactComponent as CrownIcon } from "../assets/crown.svg";
import { ReactComponent as VerifiedIcon } from "../assets/verified.svg";
import { ReactComponent as StarIcon } from "../assets/star.svg";
import { ReactComponent as GameIcon } from "../assets/game.svg";

import BetLogModalContext from "../contexts/BetLogModalContext";

function BetDetailsModalBlockElement(props: any) {
  const { iconStyle, valueStyle } = props;
  return (
    <div className="bg-accent w-full m-1 md:m-[6px] p-2 rounded-xl text-[12px]/6">
      <div className="flex flex-row text-tertiary text-base tracking-tight">
        <div className={iconStyle}>{props.icon ?? null}</div>
        {props.title}
      </div>
      <div className={valueStyle}>{props.value}</div>
    </div>
  );
}

function BetDetailsModalTitledBlockElement(props: any) {
  return (
    <div className={" ".concat(props.className ?? "")}>
      {props.title}
      <div className="bg-accent p-5 rounded-2xl mt-2 overflow-hidden break-words text-[10px] md:text-base">
        {props.value}
      </div>
    </div>
  );
}

export default function BetDetailsModal(props: any) {
  const { isBetLogModalOpen, setIsBetLogModalOpen, data } =
    React.useContext(BetLogModalContext);

  const onClose = () => {
    setIsBetLogModalOpen(false);
  };

  const modalBackgroundRef = useRef<HTMLDivElement>(null);
  const modalContainerRef = useRef<HTMLDivElement>(null);
  // modalContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  setTimeout(() => {
    modalContainerRef.current?.scrollIntoView();
    modalBackgroundRef.current?.addEventListener("scroll", (event) => {
      if ((event.target as HTMLDivElement).scrollTop < 250) {
        setIsBetLogModalOpen(false);
      }
    });
  }, 0);

  return (
    <div
      id="modal-background"
      ref={modalBackgroundRef}
      className={`w-screen h-screen absolute bottom-0 left-0 z-10 ${
        isBetLogModalOpen ? "flex" : "hidden"
      } items-end md:items-center justify-center backdrop-brightness-[0.4] overflow-y-scroll`}
    >
      <div
        id="modal-container"
        ref={modalContainerRef}
        className="bg-primary w-full md:w-1/2 overflow-hidden h-fit rounded-lg p-6 text-white flex flex-col gap-5 translate-y-full lg:translate-y-0"
      >
        <div id="modal-header" className="flex flex-row justify-between">
          <div className="flex flex-row gap-5">
            <p>Bet Details</p>
            <p className="text-[8px]/6">{data?.date}</p>
            <p className="text-[8px]/6">{data?.time}</p>
          </div>
          <CloseIcon className="w-4 h-4" onClick={onClose} />
        </div>

        <div id="modal-content">
          <div
            id="modal-profile-container"
            className="flex flex-row justify-between"
          >
            <div className="flex flex-row items-center gap-2 md:gap-5 mb-1">
              <div
                id="modal-profile-picture-container"
                className=" w-fit h-fit relative ml-1"
              >
                <img
                  id="profile-picture"
                  src={require("../assets/profile_picture_male.jpg")}
                  alt=""
                  className="w-10 h-10 md:w-12 md:h-12 lg:w-10 lg:h-10 rounded-full"
                />
                <div
                  id="vip-status-outline"
                  className="bg-[#E08857] w-fit h-fit absolute right-0 bottom-0 p-[2px] md:p-[3px] lg:p-[2px] rounded-full translate-x-2"
                >
                  <CrownIcon
                    id="vip-status-icon"
                    className="text-white w-3 h-3 md:w-4 md:h-4 lg:w-3 lg:h-3"
                  />
                </div>
              </div>
              <p>profileName</p>
              <div
                id="online-status-icon"
                className="w-2 aspect-square bg-red-500 rounded-full"
              />
            </div>
            <div className="flex flex-row items-center tracking-tighter text-[8px]/6 md:text-base">
              <p>{data?.betID}</p>
              <VerifiedIcon className="text-[#8B7AFC] m-1 inline" />
            </div>
          </div>
          <div id="modal-game-details-container">
            <div id="first-line" className="flex flex-row">
              <BetDetailsModalBlockElement
                icon={<StarIcon className="text-secondary" />}
                title="Chance"
                value={data?.chance}
              />
              <BetDetailsModalBlockElement
                icon={<GameIcon className="text-secondary" />}
                title="Game"
                value={data?.game}
              />
            </div>
            <div id="second-line" className="flex flex-row">
              <BetDetailsModalBlockElement
                title="Bet Amount"
                value={data?.betAmount}
              />
              <BetDetailsModalBlockElement title="Roll" value={data?.roll} />
              <BetDetailsModalBlockElement
                title="Profit"
                value={
                  data?.profit
                    ? data.profit > 0
                      ? `+ ${data.profit}`
                      : data.profit
                    : null
                }
                valueStyle={
                  data?.profit ?? 0 > 0 ? "text-green-500" : "text-red-500"
                }
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <BetDetailsModalTitledBlockElement
              className="flex-1"
              title="Server Seed"
              value={data?.serverSeed ?? "The seed hasn't been revealed yet."}
            />
            <BetDetailsModalTitledBlockElement
              className="flex-1"
              title="Nonce"
              value={data?.nonce ?? "Nonce coming soon"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
