import React from "react";

import { ReactComponent as VerifiedIcon } from "../assets/verified.svg";

import BetLogModalContext from "../contexts/BetLogModalContext";
import { FormattedMessage } from "react-intl";

export function BetLogHeader(props: any) {
  return (
    <div className="sticky top-0 bg-primary font-inter text-[#bdbdbd] text-[6px] md:text-[10px] tracking-[2px] pl-4 font-bold flex flex-row gap-5 pb-4">
      <div className="text-start uppercase flex-1 overflow-hidden" id="date-container">
        <FormattedMessage id="date" />
      </div>
      <div className="text-start uppercase flex-1 overflow-hidden" id="bet-id-container">
        <FormattedMessage id="betId" />
      </div>
      <div className="text-start uppercase flex-1 overflow-hidden" id="bet-amount-container">
        <FormattedMessage id="betAmount" />
      </div>
      <div className="text-start uppercase flex-1 overflow-hidden" id="chance-container">
        <FormattedMessage id="winChance" />
      </div>
      <div className="text-start uppercase flex-1 overflow-hidden" id="roll-container">
        <FormattedMessage id="roll" />
      </div>
      <div className="text-start uppercase flex-1 overflow-hidden" id="profit-container">
        <FormattedMessage id="profit" />
      </div>
    </div>
  );
}

export function BetLogCard(props: any) {
  const { setIsBetLogModalOpen, setData } =
    React.useContext(BetLogModalContext);

  const betAmount = props.betAmount;
  const betAmountWhole = betAmount.toFixed(props.digits).split(".")[0];
  const betAmountDecimal = betAmount.toFixed(props.digits).split(".")[1];

  return (
    <div
      className="even:bg-transparent bg-accent cursor-pointer text-[6px] md:text-[10px] flex flex-row gap-5 pl-4 py-4"
      onClick={() => {
        // window.scrollTo(0, 0);
        setIsBetLogModalOpen(true);
        setData({
          date: props.date,
          time: props.time,
          betID: props.betID,
          betAmount: props.betAmount,
          digits: props.digits,
          game: props.game,
          chance: props.chance,
          roll: props.roll,
          profit: props.profit,
          serverSeed: props.serverSeed,
          hashedServerSeed: props.hashedServerSeed,
          clientSeed: props.clientSeed,
          nonce: props.nonce,
        });
      }}
    >
      <div
        id="date-container"
        className=" font-inter overflow-hidden flex-1 items-center flex text-[#BDBDBD]"
      >
        <p>{props.date}</p>
      </div>
      <div
        id="bet-id-container"
        className=" font-inter overflow-hidden flex-1 items-center flex text-ellipsis"
      >
        <VerifiedIcon className="text-[#8B7AFC] m-1 " />
        <div className="w-full overflow-hidden text-ellipsis">
          <p>{props.betID}</p>
        </div>
      </div>
      <div
        id="bet-amount-container"
        className=" font-inter overflow-hidden flex-1 items-center flex"
      >
        <p className="font-extrabold inline">{betAmountWhole}</p>
        <p className="inline">.{betAmountDecimal}</p>
      </div>
      <div
        id="chance-container"
        className=" font-inter overflow-hidden flex-1 items-center flex"
      >
        <p>{props.chance}%</p>
      </div>
      <div id="roll-container" className=" font-inter overflow-hidden flex-1 items-center flex">
        <p>{props.roll}</p>
      </div>
      <div
        className={`w-[1%] ${
          props.profit > 0 ? "text-green-500" : "text-red-500"
        }  font-inter overflow-hidden flex-1 items-center flex`}
        id="profit-container"
      >
        <p className="w-fit font-inter font-bold">
          {props.profit > 0 ? "+" : ""}
          {Number(props.profit).toFixed(props.digits)}
        </p>
      </div>
    </div>
  );
}

export default function BetLogs(props: any) {
  const { betHistory } = React.useContext(BetLogModalContext);
  return (
    <React.Fragment>
      <div className={`overflow-y-scroll ${betHistory?.length ? 'min-h-[200px]' : ''} w-full text-white`}>
          <div className="sticky top-0">
            <BetLogHeader />
          </div>
          <div>
            {betHistory?.map((betLog, index) => {
              return (
                <BetLogCard
                  key={index}
                  date={betLog.date}
                  time={betLog.time}
                  betID={betLog.betID}
                  betAmount={betLog.betAmount}
                  digits={betLog.digits}
                  game={betLog.game}
                  chance={betLog.chance}
                  roll={betLog.roll}
                  profit={betLog.profit.toFixed(8)}
                  serverSeed={betLog.serverSeed}
                  hashedServerSeed={betLog.hashedServerSeed}
                  clientSeed={betLog.clientSeed}
                  nonce={betLog.nonce}
                />
              );
            })}
          </div>
      </div>
    </React.Fragment>
  );
}
