import React, { forwardRef, useEffect, useImperativeHandle } from "react";

const Slot = (props: any) => {
  return (
    <div
      id={`slot-${props.id}`}
      className="rounded-2xl border-white border-2 bg-white bg-opacity-20 flex items-center justify-center text-4xl aspect-[0.75]"
    >
      {props.value}
    </div>
  );
}

const Slots = forwardRef((props: any, ref: any) => {
  
  const [currentValue, setCurrentValue] = React.useState<number>(0);
  const [rollingInterval, setRollingInterval] = React.useState<any>(null);

  const startRolling = () => {
    setRollingInterval(
      setInterval(() => {
        setCurrentValue(Math.floor(Math.random() * 9999));
      }, 100)
    );
  };

  const stopRolling = (value: number) => {
    clearInterval(rollingInterval);
    setCurrentValue(value);
  };

  useImperativeHandle(ref, () => ({
    startRolling,
    stopRolling,
  }));

  useEffect(() => {
    setCurrentValue(props.latestValue ?? 0);
  }, [props.latestValue]);

  return (
    <React.Fragment>
      <Slot id={1} value={Math.floor(currentValue / 1000)} />
      <Slot id={2} value={Math.floor((currentValue / 100) % 10)} />
      <Slot id={3} value={Math.floor((currentValue / 10) % 10)} />
      <Slot id={4} value={Math.floor(currentValue % 10)} />
    </React.Fragment>
  );
});

export default Slots;
