import React, { createContext, useEffect } from "react";

export type BetLogDataProps = {
  date: string;
  time: string;
  betID: string;
  betAmount: number;
  digits: number;
  game: string;
  chance: number;
  roll: number;
  profit: number;
  serverSeed: string;
  hashedServerSeed: string;
  clientSeed: string;
  nonce: number;
};

export type BetLogModalContextProps = {
  isBetLogModalOpen: boolean;
  setIsBetLogModalOpen: (isBetLogModalOpen: boolean) => void;
  data: BetLogDataProps | null;
  setData: (data: BetLogDataProps) => void;
  betHistory: [BetLogDataProps] | null;
  setBetHistory: (data: [BetLogDataProps]) => void;
};

const defaultState = {
  isBetLogModalOpen: false,
  setIsBetLogModalOpen: (isBetLogModalOpen: boolean) => {},
  data: null,
  setData: (data: BetLogDataProps) => {},
  betHistory: null,
  setBetHistory: (data: [BetLogDataProps]) => {},
};

const BetLogModalContext = createContext<BetLogModalContextProps>(
  defaultState as BetLogModalContextProps
);

const betLogData = [
  {
    date: "09/21/2023",
    betID: "123456789",
    betAmount: 0.00000001,
    digits: 2,
    game: "Under500",
    chance: 50,
    roll: 50,
    profit: 0.00000001,
    serverSeed: "123456789",
    hashedServerSeed: "123456789",
    clientSeed: "123456789",
    nonce: 123456789,
  }
];

export function BetLogModalContextProvider(props: any) {
  const [isBetLogModalOpen, setIsBetLogModalOpen] =
    React.useState<boolean>(false);
  const [data, setData] = React.useState<BetLogModalContextProps["data"]>(null);
  const [betHistory, setBetHistory] = React.useState<
    BetLogModalContextProps["betHistory"]
  >(betLogData as BetLogModalContextProps["betHistory"]);

  useEffect(() => {
    if (props.betHistory) {
      setBetHistory(props.betHistory);
    }
  }, [props.betHistory]);

  return (
    <BetLogModalContext.Provider
      value={{
        isBetLogModalOpen,
        setIsBetLogModalOpen,
        data,
        setData,
        betHistory,
        setBetHistory,
      }}
    >
      {props.children}
    </BetLogModalContext.Provider>
  );
}

export default BetLogModalContext;
